import React, { useState } from 'react';
import Layout from '../components/Layout';

import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';

import arrow_right from '../assets/images/arrow_right.png';
import arrow_left from '../assets/images/arrow_left.png';
import { Link } from 'gatsby';
import satan_blocker from '../assets/images/satan-blocker.png';

const Love = () => {
  const [counter, setCounter] = useState(100);
  //increase counter
  const increase = () => {
    setCounter(count => count + 1);
  };

  //decrease counter
  const decrease = () => {
    setCounter(count => count - 1);
  };

  //reset counter
  const reset = () => {
    setCounter(100);
  };
  return (
    <Layout>
      <Sidebar showTabs="ramadan" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="top"
      >
        <ol>
          <h3>Satan Blocker </h3>
          <li>
            <a href="https://youtube.com/shorts/6k8YD9WJkYQ?feature=share">
              Recording Click Here<span></span>
            </a>
          </li>
          <li>
            <a href="https://myislam.net/static/chainsatanback.pdf">
              Slides in PDF format
            </a>
          </li>
          <li>
            <a href="https://youtu.be/3aOSfwM9KeU">YouTube Recording</a>
          </li>
          <img
            className="img-fluid img-profile mx-auto mb-2"
            src={satan_blocker}
            alt=""
            height="400"
            width="400"
          />

          <div>
            <ul>
              <p />
              <p />
              <li>
                Say the following zikr{' '}
                <span className={Classes.blink}>100 times</span>
                <ul>
                  <li>
                    <h6>Arabic:</h6>
                    <p className={Classes.blink}>
                      لا إلهَ إلاَّ اللَّهُ وَحْدَهُ لاَ شَرِيكَ لَهُ، لَهُ
                      المُلْكُ وَلَهُ الحَمْدُ وَهُوَ عَلى كُلّ شَيْءٍ قَدِيرٌ{' '}
                    </p>
                    <div className="counter">
                      <span className="counter__output">
                        <h4 className={Classes.blink}>Remaining = {counter}</h4>
                      </span>
                      <div className="btn__container">
                        <button className="control__btn" onClick={decrease}>
                          Count 1
                        </button>
                        <button className="reset" onClick={reset}>
                          Reset
                        </button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <h6>English:</h6>{' '}
                    <p>
                      There is no god but Allah, alone, without any partner. The
                      kingdom and praise belong to him and he has power over
                      everything.{' '}
                    </p>
                  </li>
                  <li>
                    <h6>Transletration:</h6>
                    <p>
                      (La ilah illa 'llah, wahdahu la sharik lah, lahu 'l mulku
                      wa lahu 'l hamd, wa huwa ala kulli shay'in qadir){' '}
                    </p>
                  </li>
                </ul>
                <li>
                  <a href="https://sunnah.com/mishkat:2302" target="blank">
                    Full Hadith Click Here<span></span>
                  </a>
                </li>
              </li>
              <li>
                <a href="https://youtube.com/shorts/6k8YD9WJkYQ?feature=share">
                  Recording Click Here<span></span>
                </a>
              </li>
            </ul>
            <p />
            <h3>References</h3>
            <ul>
              <li>
                <a href="https://youtu.be/lrV6gv7_K9Y">
                  هذا الذكر حرز من الشيطان - للشيخ صالح المغامسي{' '}
                </a>
              </li>
              <li>
                <a href="https://youtu.be/xPXBowVCeEA">
                  قول النبي من قال ( لا إله إلا الله وحده لا شريك له ... في يوم
                  مئة مرة) هل يلزم أن يقال دفعة واحدة{' '}
                </a>
              </li>
              <li>
                <a href="https://islamweb.net/ar/fatwa/141531/%D8%B0%D9%83%D8%B1-%D9%84%D8%A7-%D8%A5%D9%84%D9%87-%D8%A5%D9%84%D8%A7-%D8%A7%D9%84%D9%84%D9%87-%D9%88%D8%AD%D8%AF%D9%87-%D9%84%D8%A7-%D8%B4%D8%B1%D9%8A%D9%83-%D9%84%D9%87-%D9%81%D8%B6%D9%84%D9%87-%D9%88%D9%85%D8%AA%D9%89-%D9%8A%D9%82%D8%A7%D9%84-%D9%88%D9%83%D9%85-%D9%8A%D9%82%D8%A7%D9%84">
                  ذكر لا إله إلا الله وحده لا شريك له .. فضله ومتى يقال وكم يقال
                </a>
              </li>
              <li>
                <a href="https://islamqa.info/ar/answers/259154/%D8%A7%D8%AC%D8%B1-%D9%85%D9%86-%D9%82%D8%A7%D9%84-%D9%84%D8%A7-%D8%A7%D9%84%D9%87-%D8%A7%D9%84%D8%A7-%D8%A7%D9%84%D9%84%D9%87-%D9%88%D8%AD%D8%AF%D9%87-%D9%84%D8%A7-%D8%B4%D8%B1%D9%8A%D9%83-%D9%84%D9%87-%D9%84%D9%87-%D8%A7%D9%84%D9%85%D9%84%D9%83-%D9%88%D9%84%D9%87-%D8%A7%D9%84%D8%AD%D9%85%D8%AF-%D9%88%D9%87%D9%88-%D8%B9%D9%84%D9%89-%D9%83%D9%84-%D8%B4%D9%8A%D8%A1-%D9%82%D8%AF%D9%8A%D8%B1">
                  أجر من قال لا إله إلا الله{' '}
                </a>
              </li>

              <li>
                <a href="https://www.islamweb.net/ar/fatwa/199359/%D8%B1%D9%88%D8%A7%D9%8A%D8%A7%D8%AA-%D8%AD%D8%AF%D9%8A%D8%AB-%D9%85%D9%86-%D9%82%D8%A7%D9%84-%D8%A5%D8%B0%D8%A7-%D8%A3%D8%B5%D8%A8%D8%AD-%D9%84%D8%A7-%D8%A5%D9%84%D9%87-%D8%A5%D9%84%D8%A7-%D8%A7%D9%84%D9%84%D9%87-%D9%88%D8%AD%D8%AF%D9%87">
                  Other Narrations{' '}
                </a>
              </li>

              <li>
                <a href="https://www.alukah.net/sharia/0/85644/%D8%AA%D8%AE%D8%B1%D9%8A%D8%AC-%D8%AD%D8%AF%D9%8A%D8%AB-%D9%85%D9%86-%D9%82%D8%A7%D9%84-%D8%A5%D8%B0%D8%A7-%D8%A3%D8%B5%D8%A8%D8%AD-%D9%84%D8%A7-%D8%A5%D9%84%D9%87-%D8%A5%D9%84%D8%A7-%D8%A7%D9%84%D9%84%D9%87-%D9%88%D8%AD%D8%AF%D9%87-%D9%84%D8%A7-%D8%B4%D8%B1%D9%8A%D9%83-%D9%84%D9%87/">
                  تخريج حديث: (من قال إذا أصبح: لا إله إلا الله وحده لا شريك له)
                </a>
              </li>
              <li>
                <a href="https://islamqa.info/ar/answers/259154/%D8%A7%D8%AC%D8%B1-%D9%85%D9%86-%D9%82%D8%A7%D9%84-%D9%84%D8%A7-%D8%A7%D9%84%D9%87-%D8%A7%D9%84%D8%A7-%D8%A7%D9%84%D9%84%D9%87-%D9%88%D8%AD%D8%AF%D9%87-%D9%84%D8%A7-%D8%B4%D8%B1%D9%8A%D9%83-%D9%84%D9%87-%D9%84%D9%87-%D8%A7%D9%84%D9%85%D9%84%D9%83-%D9%88%D9%84%D9%87-%D8%A7%D9%84%D8%AD%D9%85%D8%AF-%D9%88%D9%87%D9%88-%D8%B9%D9%84%D9%89-%D9%83%D9%84-%D8%B4%D9%8A%D8%A1-%D9%82%D8%AF%D9%8A%D8%B1">
                  Do we Say it contiously or through the day?{' '}
                </a>
              </li>
              <li>
                <a href="https://khaledalsabt.com/explanations/378/182-%D8%A7%D8%B0%D9%83%D8%A7%D8%B1-%D8%A7%D9%84%D8%B5%D8%A8%D8%A7%D8%AD-%D9%88%D8%A7%D9%84%D9%85%D8%B3%D8%A7%D8%A1-%D9%84%D8%A7-%D8%A7%D9%84%D9%87-%D8%A7%D9%84%D8%A7-%D8%A7%D9%84%D9%84%D9%87-%D9%88%D8%AD%D8%AF%D9%87-%D9%84%D8%A7-%D8%B4%D8%B1%D9%8A%D9%83-%D9%84%D9%87-%D9%84%D9%87-%D8%A7%D9%84%D9%85%D9%84%D9%83-%D9%88%D9%84%D9%87-%D8%A7%D9%84%D8%AD%D9%85%D8%AF-%D9%88%D9%87%D9%88-%D8%B9%D9%84%D9%89-%D9%83%D9%84-%D8%B4%D9%8A%D8%A1-%D9%82%D8%AF%D9%8A%D8%B1">
                  Another Naration One time{' '}
                </a>
              </li>
              <li>
                <a href="https://quranbysubject.com/category.php?category=7ec1b221-3e20-11e4-8652-000c29db8d9b">
                  ايات القران التي تتكلم عن: الشيطان{' '}
                </a>
              </li>
              <li>
                <a href="https://quranbysubject.com/category.php?category=3685db91-3e20-11e4-8652-000c29db8d9b">
                  ايات القران التي تتكلم عن: خلق ادم و الانسان{' '}
                </a>
              </li>
              <li>
                <a href="https://islamqa.info/ar/answers/26226/%D9%84%D9%83%D9%84-%D8%A7%D9%86%D8%B3%D8%A7%D9%86-%D9%82%D8%B1%D9%8A%D9%86-%D9%85%D9%86-%D8%A7%D9%84%D8%AC%D9%86">
                  لكل إنسان قرين من الجن
                </a>
              </li>
              <li>
                <a href="https://islamqa.info/amp/ar/answers/149459/">
                  ما هي وظيفة القرين من الجن{' '}
                </a>
              </li>
              <li>
                <a href="https://islamqa.info/ar/answers/23415/%D9%87%D9%84-%D9%8A%D8%AC%D9%88%D8%B2-%D8%A7%D9%86-%D8%A7%D8%AF%D8%B9%D9%88-%D9%84%D9%82%D8%B1%D9%8A%D9%86%D9%8A-%D9%85%D9%86-%D8%A7%D9%84%D8%AC%D9%86-%D8%A8%D8%A7%D9%84%D8%A7%D8%B3%D9%84%D8%A7%D9%85">
                  هل يجوز أن أدعو لقريني من الجن بالإسلام
                </a>
              </li>
              <li>
                <a href="https://www.islamicity.org/">
                  https://www.islamicity.org/{' '}
                </a>
              </li>
              <li>
                <a href="https://quranx.com//">https://quranx.com// </a>
              </li>
              <li>
                <a href="https://sunnah.com/" target="blank">
                  https://sunnah.com/
                </a>
              </li>
              <li>
                <a href="https://www.dorar.net/hadith" target="blank">
                  https://www.dorar.net/hadith
                </a>
              </li>
            </ul>
          </div>
        </ol>
      </section>
      <section></section>
    </Layout>
  );
};

export default Love;
